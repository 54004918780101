
function TeamAbout() {
    return (
        <>
            <section className="appie-team-area appie-team-about-area pb-90 innerdivp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Contact us</h3>
                                <div className='innp'>
                                
                                <p>
                                   
                                   <a href="mailto:support@looksmart.com" class="email">Email</a><br/>
                                310-596-1990<br/>2850 W Horizon Ridge Pkwy, Ste 200<br/>Henderson, Nevada, 89052
                                </p>
                                
                                
</div>
                                </div>
                        </div>
                    </div>
                  
                </div>
            </section>
        </>
    );
}

export default TeamAbout;
