import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeEight/FooterHomeEight';
import HeaderHomeEight from '../HomeEight/HeaderHomeEight';
import Drawer from '../Mobile/Drawer';
import TeamAbout from './TeamAbout';

function AboutUs() {
     const [drawer, setDrawer] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={setDrawer.toggle} />
            <HeaderHomeEight drawer={drawer} action={setDrawer.toggle} />
            
            <TeamAbout />
           
            <FooterHomeOne className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AboutUs;
