
function TeamAbout() {
    return (
        <>
            <section className="appie-team-area appie-team-about-area pb-90 innerdivp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Terms and conditions</h3>
                                <div className='innp'>
                                <h4>Introduction</h4>
                                <p>The following terms and conditions govern all use of LookSmart extensions and all content, services and products available at or through the extensions. The extensions are offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Gadgetly Privacy Policy). Please read this Agreement carefully before accessing or using our extensions. By accessing or using any part of our extensions, you agree to be bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access our extensions or use any services. If these terms and conditions are considered an offer by LookSmart, acceptance is expressly limited to these terms.
</p>
                                <h4>Ownership</h4>
                                <p>You do not claim intellectual property rights or exclusive ownership to any of our assets, modified or unmodified. All assets are property of Gadgetly Tab. Our assets are provided ‘as is’ without warranty of any kind, either expressed or implied. In no event shall our company be liable for any damages including, but not limited to, direct, indirect, special, punitive, incidental or consequential, or other losses arising out of the use of or inability to use our assets.</p>
                                
                                <h4>Responsibility of Users</h4>
                                <p>Gadgetly Tab has not reviewed, and cannot review, all of the material, being presented in search results from the extension, and cannot therefore be responsible for that material’s content, use or effects. By operating the extension, Gadgetly Tab does not represent or imply that it endorses the material there posted, or that it believes such material to be accurate, useful or non-harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. Search results may contain content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. The content posted on other websites may also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights of third parties, or downloading, copying or use of which is subject to additional terms and conditions, stated or unstated. Gadgetly Tab disclaims any responsibility for any harm resulting from the use by visitors of the extension</p>
                                
                                <h4>Copyright Infringement and DMCA Policy</h4>
                                <p>As GadgetlyTab asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to by Gadgetly Tab violates your copyright, you are encouraged to notify Gadgetly Tab. Gadgetly Tab will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. In the case of a visitor who may infringe or repeatedly infringes the copyrights or other intellectual property rights of Gadgetly Tab or others, Gadgetly Tab may, in its discretion, terminate or deny access to and use of the extension.</p>

                                 <h4>Intellectual Property</h4>
                                <p>This Agreement does not transfer from Gadgetly Tab to you any Gadgetly Tab or third-party intellectual property, and all right, title and interest in and to such property will remain (as between the parties) solely with Gadgetly Tab. GadgetlyTab, GadgetlyTab.com, the Gadgetly Tab logo, and all other trademarks, service marks, graphics and logos used in connection with the Gadgetly TabExtension, and the Gadgetly Tab Website are trademarks or registered trademarks of Gadgetly Tab or Gadgetly Tab’s licensors. Other trademarks, service marks, graphics and logos used in connection with the extension and website may be the trademarks of other third parties. Your use of Gadgetly Tab grants you no right or license to reproduce or otherwise use any Gadgetly Tab or third-party trademarks.</p>

                                <h4>Changes</h4>
                                <p>Gadgetly Tab Reserves the right, at its sole discretion, to modify or replace any part of this Agreement without notice. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the extension following the posting of any changes to this Agreement constitutes acceptance of those changes. Gadgetly Tab may also, in the future, offer new services and/or features through the extension (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.</p>
                                
                                 <h4>Termination</h4>
                                <p>Gadgetly Tab may terminate your access to all or any part of the extension at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement, you may simply remove Gadgetly Tab from the extension store and discontinue using the extension. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

                                <h4>Limitation of Liability</h4>
                                <p>In no event will Gadgetly Tab be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; or (ii) for interruption of use or loss or corruption of data. Gadgetly Tab shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.</p>

                                <h4>General Representation and Warranty</h4>
                                <p>You represent and warrant that (i) your use of the extension will be in strict accordance with the Gadgetly Tab Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the country in which you reside) and (ii) your use of the extension will not infringe or misappropriate the intellectual property rights of any third party.</p>

                                <h4>Indemnification</h4>
                                <p>You agree to indemnify and hold harmless Gadgetly Tab, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorney fees, arising out of your use of the extension, including but not limited to out of your violation of this Agreement.</p>
                                
                                 <h4>Site Terms of Use Modifications</h4>
                                <p>Gadgetly Tab may revise these terms of use for its extension at any time without notice. By using this extension, you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>

                                
</div>
                                </div>
                        </div>
                    </div>
                  
                </div>
            </section>
        </>
    );
}

export default TeamAbout;
