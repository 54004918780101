import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUsNew';
import App from './components/App';
import ContactUs from './components/ContactUs';
import Error from './components/Error';
import Extension from './components/Extension';
import GadgetlyAbout from './components/GadgetlyAbout';
import GadgetlyContact from './components/GadgetlyContact';
import GadgetlyPrivacy from './components/GadgetlyPrivacy';
import GadgetlyTerms from './components/GadgetlyTerms';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeEight from './components/HomeEight';
import Mobileprivacy from './components/Mobileprivacy';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

function Routes() {   
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    });
    return (
        <>
            
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeEight} />
                            <Route exact path="/app" component={App} />
                            <Route exact path="/terms" component={Terms} />
                            <Route exact path="/privacy" component={Privacy} />
                            <Route exact path="/mobileprivacy" component={Mobileprivacy} />
                            <Route exact path="/aboutUs" component={AboutUs} />
                            <Route exact path="/about" component={AboutUs} />
                            <Route exact path="/contact" component={ContactUs} />
                            <Route exact path="/contactUs" component={ContactUs} />
                            <Route exact path="/extension" component={Extension} />
                            <Route exact path="/gadgetly" component={Extension} />
                            <Route exact path="/gadgetly/terms" component={GadgetlyTerms} />
                            <Route exact path="/gadgetly/privacy" component={GadgetlyPrivacy} />
                            <Route exact path="/gadgetly/about" component={GadgetlyAbout} />
                            <Route exact path="/gadgetly/contact" component={GadgetlyContact} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
