import React from 'react';

function TeamAbout() {
    return (
        <>
            <section className="appie-team-area appie-team-about-area pb-90 innerdivp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">About Us</h3>
                                <div className='innp'>
                              
                                <p>A Search Pioneer, LookSmart is a veteran of the online search advertising industry. Founded in 1997, LookSmart utilized a directory model to become one of the first consumer-facing search Web sites. Over the years since its first foray into search, LookSmart developed a search advertising platform to support the LookSmart network as well as other industry leaders including MSN and Ask Sponsored Listings</p>
                                
                                <p>Today, LookSmart is a premier search product development and management company. 
Throughout its growth and development, LookSmart has demonstrated its commitment to upholding the integrity of search advertising. LookSmart was one of the five founding members of the IAB's Click Measurement Panel in 2006. And to protect its advertisers and their advertising investments, LookSmart also developed its own arsenal of tools and processes to help identify and filter invalid traffic. These included automated fraud detection controls to help catch instances of unusual activity such as spiders, bots, and click fraud; proprietary filtering software to help identify and remove non-converting traffic from customers' bills and rigorous partner reviews to pre-screen sites for traffic quality and CTR consistency.
</p>
                                
                                
                                
</div>
                                </div>
                        </div>
                    </div>
                  
                </div>
            </section>
        </>
    );
}

export default TeamAbout;
