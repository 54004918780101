import { Link } from 'react-router-dom';

function FooterHomeEight() {
    return (
      <>
        <section className="appie-footer-area appie-footer-about-area appie-footer-8-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-navigation">
                  <h4 className="title">LookSmart</h4>
                  <p>2850 W Horizon Ridge Pkwy, Ste 200</p>
                  <p>Henderson, Nevada, 89052 </p>
                  <p>United States</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-navigation">
                  <h4 className="title">About</h4>
                  <ul>
                    
                    <li>
                      
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      
                      <Link to="/terms">Terms</Link>
                    </li>

                    <li>
                      
                      <Link to="/aboutUs">About Us</Link>
                    </li>
                    <li>
                      
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-navigation">
                  <h4 className="title">Extension</h4>
                  <ul>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.smartbase">Mobile App</a>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                >
                  <div className="apps-download-btn">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-apple"></i> Download for iOS
                        </a>
                      </li>
                      <li>
                        <a className="item-2" href="#">
                          <i className="fab fa-google-play"></i> Download for
                          Android
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="copyright-text">
                    <p>Copyright © 2021 Appie. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default FooterHomeEight;
